import { Box, Link, Typography } from "@material-ui/core";
import HeartIcon from "@material-ui/icons/Favorite";

export default function CremaHeart() {
  return (
    <Link
      href="https://crema.us"
      style={{
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <HeartIcon fontSize="small" color="secondary" />
      <Box ml={0.5} mr={1}>
        <Typography color="textPrimary">by</Typography>
      </Box>
      <svg width="50" viewBox="0 0 190 49" fill="white">
        <path d="M35.598 46.8663C32.5595 48.2537 29.075 48.9196 25.1165 48.9196C21.5205 48.9196 18.2032 48.3369 15.1368 47.1437C12.0983 45.9506 9.45007 44.2857 7.19209 42.1214C4.96198 39.957 3.20577 37.3765 1.92346 34.3519C0.641155 31.3274 0 28.0254 0 24.3904C0 20.6722 0.641155 17.3147 1.95134 14.2902C3.23365 11.2657 5.04561 8.71285 7.33147 6.57626C9.61732 4.46742 12.2934 2.83029 15.3598 1.69262C18.4262 0.554959 21.7435 0 25.3117 0C28.6011 0 31.8347 0.582707 35.0126 1.74812C38.1905 2.91353 40.783 4.60616 42.7622 6.82599L35.1241 14.4289C34.0648 12.986 32.6989 11.9039 30.9706 11.2102C29.2701 10.5165 27.5139 10.1557 25.7019 10.1557C23.7227 10.1557 21.9108 10.5165 20.2661 11.2379C18.6214 11.9594 17.1997 12.9583 16.0289 14.2069C14.8302 15.4833 13.9103 16.9817 13.2691 18.7021C12.6001 20.4225 12.2934 22.3093 12.2934 24.3627C12.2934 26.4715 12.628 28.3861 13.2691 30.1343C13.9382 31.8824 14.8302 33.3808 16.001 34.6294C17.1718 35.8781 18.5656 36.8493 20.1824 37.543C21.7993 38.2367 23.5834 38.5974 25.5068 38.5974C27.7369 38.5974 29.6883 38.1534 31.3608 37.2932C33.0334 36.433 34.3715 35.2954 35.375 33.8802L43.2083 41.2334C41.1733 43.6475 38.6366 45.5066 35.598 46.8663Z" />
        <path d="M66.5673 23.8981C66.0948 23.7597 65.6224 23.6766 65.15 23.649C64.6775 23.5936 64.2329 23.5936 63.816 23.5936C62.3709 23.5936 61.1481 23.8427 60.1754 24.3686C59.2027 24.8944 58.3968 25.531 57.8132 26.2506C57.2295 26.9978 56.7849 27.8004 56.5348 28.6584C56.2847 29.5164 56.1457 30.2913 56.1457 30.9279V47.6168H45.3906V15.1246H55.7566V19.8296H55.8956C56.7293 18.169 57.8965 16.8129 59.4528 15.7612C60.9813 14.7094 62.7599 14.1836 64.7609 14.1836C65.2055 14.1836 65.6224 14.2113 66.0393 14.2389C66.4561 14.2943 66.7896 14.3497 67.0675 14.4327L66.5673 23.8981Z" />
        <path d="M100.966 31.7818C100.966 32.2261 100.966 32.6703 100.966 33.0868C100.966 33.5311 100.938 33.9476 100.91 34.3363H77.1231C77.2068 35.2526 77.4856 36.0856 77.9597 36.8353C78.4337 37.585 79.0194 38.2236 79.7444 38.779C80.4694 39.3343 81.2781 39.7508 82.1705 40.0562C83.0628 40.3616 84.011 40.5282 84.9591 40.5282C86.6602 40.5282 88.1102 40.2228 89.3093 39.5842C90.5084 38.9456 91.4566 38.1403 92.2095 37.113L99.7109 41.8333C98.1771 44.0546 96.1414 45.7761 93.6038 46.9701C91.0662 48.164 88.1381 48.7749 84.8197 48.7749C82.3657 48.7749 80.0233 48.3861 77.8481 47.6364C75.6451 46.859 73.721 45.7483 72.0757 44.3045C70.4304 42.8329 69.1198 41.028 68.1995 38.89C67.2514 36.752 66.7773 34.3086 66.7773 31.5319C66.7773 28.8663 67.2514 26.4507 68.1716 24.2849C69.0919 22.1191 70.3468 20.2865 71.9363 18.7871C73.5258 17.2877 75.3942 16.1215 77.5414 15.2886C79.6886 14.4556 82.0311 14.0391 84.5129 14.0391C86.939 14.0391 89.142 14.4556 91.1777 15.2608C93.1855 16.066 94.9423 17.2322 96.3924 18.7594C97.8425 20.2865 98.9579 22.1469 99.7945 24.3404C100.547 26.4784 100.966 28.9774 100.966 31.7818ZM91.0104 27.6446C91.0104 25.9509 90.4805 24.4792 89.393 23.2575C88.3054 22.0358 86.7159 21.4249 84.5408 21.4249C83.4811 21.4249 82.533 21.5915 81.6406 21.9247C80.7483 22.2579 79.9954 22.7022 79.3261 23.2575C78.6568 23.8128 78.127 24.507 77.7366 25.2567C77.3462 26.0342 77.1231 26.8116 77.0673 27.6446H91.0104Z" />
        <path d="M145.632 47.5893V29.7605C145.632 28.8455 145.576 27.9583 145.436 27.0987C145.297 26.2392 145.074 25.5182 144.712 24.9082C144.349 24.2982 143.875 23.7991 143.29 23.4387C142.704 23.0782 141.924 22.8841 140.948 22.8841C140.028 22.8841 139.219 23.0782 138.55 23.4664C137.881 23.8546 137.296 24.3814 136.85 25.0469C136.376 25.7123 136.041 26.461 135.818 27.3205C135.595 28.1801 135.483 29.0674 135.483 29.9824V47.5893H124.694V28.9842C124.694 27.0987 124.332 25.6291 123.579 24.5201C122.826 23.4387 121.655 22.8841 120.01 22.8841C118.31 22.8841 116.971 23.5496 116.023 24.9082C115.076 26.2669 114.602 27.9305 114.602 29.8992V47.5893H103.729V14.9818H114.211V19.5014H114.351C114.741 18.8082 115.243 18.115 115.828 17.4773C116.414 16.8118 117.139 16.2573 117.975 15.7304C118.811 15.2313 119.731 14.8154 120.763 14.5104C121.795 14.2054 122.938 14.0391 124.164 14.0391C126.534 14.0391 128.541 14.5104 130.186 15.4809C131.831 16.4514 133.114 17.81 134.034 19.6123C135.121 17.9209 136.543 16.5623 138.243 15.5641C139.944 14.5659 142.035 14.0668 144.489 14.0668C146.719 14.0668 148.615 14.4827 150.12 15.2868C151.626 16.0909 152.852 17.1445 153.772 18.42C154.692 19.6955 155.362 21.1373 155.78 22.7455C156.198 24.3537 156.393 25.9064 156.393 27.4314V47.617H145.632V47.5893Z" />
        <path d="M160.255 19.5538C162.181 17.7248 164.441 16.3392 167.008 15.4247C169.575 14.5102 172.198 14.0391 174.876 14.0391C177.639 14.0391 179.982 14.3716 181.908 15.0644C183.833 15.7295 185.368 16.7826 186.567 18.1959C187.767 19.6092 188.632 21.4105 189.162 23.5721C189.72 25.7336 189.999 28.2832 189.999 31.2761V47.7095H180.122V44.2454H179.927C179.09 45.6033 177.834 46.6564 176.132 47.3769C174.43 48.1251 172.616 48.4854 170.635 48.4854C169.324 48.4854 167.956 48.3191 166.561 47.9589C165.166 47.5986 163.883 47.0444 162.711 46.2684C161.539 45.4925 160.59 44.4394 159.837 43.1369C159.083 41.8345 158.721 40.2272 158.721 38.2873C158.721 35.9318 159.362 34.0196 160.674 32.5786C161.957 31.1375 163.632 30.029 165.696 29.2531C167.733 28.4772 170.021 27.9506 172.505 27.6735C175.016 27.4241 177.443 27.2855 179.815 27.2855V26.759C179.815 25.1517 179.257 23.9601 178.113 23.1841C176.969 22.4082 175.574 22.0479 173.9 22.0479C174.709 22.0479 170.886 22.3805 169.463 23.0178C168.04 23.6829 166.812 24.4589 165.808 25.3734L160.255 19.5538ZM180.094 33.4931H178.699C177.499 33.4931 176.327 33.5485 175.099 33.6593C173.9 33.7702 172.811 33.9642 171.863 34.269C170.914 34.5739 170.133 35.0172 169.519 35.5992C168.905 36.1812 168.598 36.9571 168.598 37.927C168.598 38.5367 168.738 39.0632 169.017 39.5066C169.296 39.95 169.658 40.2826 170.105 40.5597C170.551 40.8091 171.054 41.0031 171.612 41.1139C172.17 41.2248 172.728 41.2802 173.258 41.2802C175.462 41.2802 177.136 40.6706 178.308 39.4789C179.48 38.2873 180.066 36.6523 180.066 34.6016V33.4931H180.094Z" />
      </svg>
    </Link>
  );
}
